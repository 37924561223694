import Layout from '../components/layout'
import Head from '../components/head'
import { Spring } from 'react-spring/renderprops'
import React from 'react'
import Carousel from 'nuka-carousel';

const images = [
    { id: 3, src: require('../images/oslo.jpg'), title: 'Frogner Park in Oslo', description: 'bar' },
    { id: 4, src: require('../images/IMG-2355.jpg'), title: 'Kayaking at Jordan Lake', description: 'bar' },
    { id: 5, src: require('../images/IMG-2413.jpg'), title: 'UNC vs Duke', description: 'bar' },
    { id: 6, src: require('../images/IMG-2552-Original.jpg'), title: 'Workout', description: 'bar' },
    { id: 7, src: require('../images/IMG-2751.jpg'), title: 'Yosemite: El Capitan', description: 'bar' },
    { id: 8, src: require('../images/IMG-1387.jpg'), title: 'Copenhagen', description: 'bar' },
    { id: 9, src: require('../images/IMG-1748.jpg'), title: 'Hilton Head Island', description: 'bar' },
  ];

export default class BioPage extends React.Component {
    render() {
      return (
        <div >
        <Layout>
            <Head title="Bio" />

            <h1>Biography</h1>
            <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props => <div style={props}>
                <p>
                Currently, I am a Software Engineer at Amazon where I am working on optimizing and automating marketing for the various payment products offered by Amazon. 
                Before this, I studied Computer Science and Statistics at the University of North Carolina at Chapel Hill.
                At UNC, I worked as a Teaching Assistant for COMP 411: Computer Organization and as an IT Consultant for the College of Arts and Sciences at UNC. 
                In my free time you can find me at the gym, the Dean Dome (Go Heels!), or traveling. 
                </p>
              </div>}
            </Spring>
        
                    <center>
                {/* <img className={headStyle.imgAM} src= width="200" height="200" alt="bball"></img>
                <img className={headStyle.imgAM} src= width="300" height="200" alt="travel"></img>
                <img className={headStyle.imgAM} src={Workout} width="200" height="200" alt="workout"></img> */}
                <Carousel slideWidth="80%" heightMode="current" width="80%" animation='zoom' cellAlign='center'>

                {/* <img src={oslo} width="200" height="200" alt="workout"></img> */}
                 {images.map(({id, src, title, description}) => <img key={id} src={src} title={title} alt={description} />)}
        </Carousel>
            </center>
            

        </Layout>
    </div>
        
      );
    }
  }
